import { useContext, useRef } from "react";
import { onTextChange, onElementClick, AppStateContext } from "../../../shared";
import ContentEditable from "react-contenteditable";
import { useImmer } from "use-immer";

export function CategoryBlock(props) {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { selectedElement } = appState;
  const { categoryTitle, categories } = appState.storeTemplate;
  const categoryTitleRef = useRef(null);
  const [state, setState] = useImmer(null);

  const onCategoryClick = (i) => {
    appScopeDispatch({
      type: "ON_CATEGORY_CLICK",
      payload: {
        index: i,
        selectedElementType: "category-link",
        selectedElement: "categories",
      },
    });
  };

  return (
    <div className="category-block-wrapper">
      <div
        className="category-block-container container container-max"
        style={{
          backgroundColor: `#ffffff`,
        }}
      >
        <div className="category-block-title">
          <ContentEditable
            onClick={(e) => {
              e.stopPropagation();
              onElementClick(appScopeDispatch, "categoryTitle", "text");
            }}
            style={{
              color: categoryTitle.color,
            }}
            className="title"
            innerRef={categoryTitleRef}
            html={categoryTitle.text} // innerHTML of the editable div
            disabled={false} // use true to disable editing
            onChange={(e) =>
              onTextChange(appScopeDispatch, "categoryTitle", e.target.value)
            } // handle innerHTML change
            tagName="span"
          />
        </div>
        <div className="category-block-items">
          {categories.map((data, index) => (
            <div
              key={index}
              onClick={() => {
                setState(index);
                onCategoryClick(index);
              }}
              className={`category-items ${
                selectedElement === "categories" && index === state
                  ? "element-selected"
                  : ""
              }`}
            >
              <div
                className="category-cover-box"
                style={{
                  backgroundColor: `${data.color}`,
                }}
              >
                <img className="" src={data.cover} />
              </div>
              <span
                className="category-name"
                style={{
                  color: `#4D4D4D`,
                }}
              >
                {data.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
