import { useContext } from "react";
import ContentEditable from "react-contenteditable";
import { onElementClick } from "../../../shared";
import { AppStateContext } from "../../../shared/contexts";

export function OrderOnlineBlock(props) {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { orderOnlineSection } = appState.storeTemplate;
  const { selectedElement } = appState;
  const {
    backgroundColor,
    headerText,
    headerColor,
    description,
    descriptionColor,
    btnText,
    btnBgColor,
    btnColor,
    link,
  } = orderOnlineSection;

  const onChange = (name, value) => {
    appScopeDispatch({
      type: "ON_ORDER_ONLINE_SECTION_CHANGE",
      payload: {
        name,
        value,
      },
    });
  };
  return (
    <div
      className="order-online-block-wrapper"
      onClick={() =>
        onElementClick(
          appScopeDispatch,
          "orderOnlineSection",
          "order-online-section"
        )
      }
    >
      <div
        className={`order-online-block-container ${
          selectedElement === "orderOnlineSection" ? "element-selected" : ""
        }`}
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <div
          className="order-online-block-title"
          style={{
            color: headerColor,
          }}
        >
          {headerText}
        </div>

        <ContentEditable
          className="order-online-block-description"
          style={{
            color: descriptionColor,
          }}
          html={description} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={(e) => onChange("description", e.target.value)}
          tagName="div"
        />
        <button
          className="order-online-block-button"
          style={{
            color: `#fffceb`,
            backgroundColor: btnBgColor,
          }}
        >
          <ContentEditable
            html={btnText} // innerHTML of the editable div
            disabled={false} // use true to disable editing
            onChange={(e) => onChange("btnText", e.target.value)}
            tagName="div"
          />
        </button>
      </div>
    </div>
  );
}
