import styled from "styled-components";

export function WeAreAvailableBlock(data) {
  const { weAreAvailable } = data.data;
  const Button = styled.div`
    background-color: ${(props) => props.theme};
  `;
  return (
    <div className="we-are-available-block-wrapper">
      <div className="we-are-available-block-container container container-max">
        <div className="we-are-available-box">
          <div className="we-are-available-title">We are available on</div>
          <div className="we-are-available-items">
            {weAreAvailable.map((data, index) => (
              <a target="_blank" href={data.link} rel="noopener noreferrer">
                <Button className="we-are-available-item" theme={data.btnColor}>
                  <img src={data.logo} />
                </Button>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
