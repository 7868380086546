import React, { useContext } from "react";
import { AppStateContext } from "../../../shared";

export const StoreTemplateSections = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { isSectionVisible } = appState.storeTemplate;
  const {
    mainBanner,
    orderSection,
    banner2,
    textSection,
    banner3,
    orderOnlineSection,
    favouriteSection,
    deliveryPartners,
    supporterSection,
  } = isSectionVisible;
  const handleSectionClick = (name) => {
    appScopeDispatch({
      type: "ONCLICK_SECTION_STORE",
      payload: {
        name,
      },
    });
  };
  const Section = ({ name, value, text }) => {
    return (
      <div
        className="section-editor-sections"
        onClick={() => handleSectionClick(name)}
      >
        <span className={value ? "" : "hidden"}>{text}</span>
        {value ? (
          <img src="/icons/eye_open.svg" />
        ) : (
          <img src="/icons/eye_close.svg" />
        )}
      </div>
    );
  };
  return (
    <div className="section-editor-wrapper">
      <Section name="mainBanner" value={mainBanner} text="Banner 1" />
      <Section name="orderSection" value={orderSection} text="Order Section" />
      <Section name="banner2" value={banner2} text="Banner 2" />
      <Section name="textSection" value={textSection} text="Description" />
      <Section name="banner3" value={banner3} text="Banner 3" />
      <Section
        name="orderOnlineSection"
        value={orderOnlineSection}
        text="Order Online section"
      />
      <Section
        name="favouriteSection"
        value={favouriteSection}
        text="Favourites Section"
      />
      <Section
        name="deliveryPartners"
        value={deliveryPartners}
        text="Delivery Partners"
      />
      <Section
        name="supporterSection"
        value={supporterSection}
        text="Proudly Supporting"
      />
    </div>
  );
};
