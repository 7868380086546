export function ProudlySupportBlock(data) {
  const { proudlySupp } = data.data;
  return (
    <div className="proudly-support-block-wrapper">
      <div className="proudly-support-block-container container container-max">
        <div className="proudly-support-block-flexbox">
          <div className="proudly-support-block-title">Proudly Supporting</div>
          <div className="proudly-support-block-items">
            {proudlySupp.map((data, index) => (
              <a target="_blank" href={data.link} rel="noopener noreferrer">
                <img alt={`supporting${index}`} src={data.logo} key={index} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
