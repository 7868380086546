import React from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from "reactstrap";
import { BanjosInput } from "../../../shared";
import parse from "html-react-parser";

export const FooterEditorTool = ({
  state,
  onAddColumn,
  onDeleteColumn,
  onAddItem,
  onDeleteItem,
  onChangeItem,
  onHide,
  onExternalChange,
}) => {
  return (
    <div className="footer-tool">
      <UncontrolledAccordion flush className="banjos-accordian">
        {state.columns.map((column, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={`${index}`}>
              <div className="header">
                <div className="text">{parse(column.title)}</div>
                <div>
                  {column.show ? (
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        onHide("column", null, index);
                      }}
                      src="/icons/eye_open.svg"
                    />
                  ) : (
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        onHide("column", null, index);
                      }}
                      src="/icons/eye_close.svg"
                    />
                  )}
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteColumn(column.id);
                    }}
                    width={17}
                    height={20}
                    src="/icons/delete_icon.svg"
                  />
                </div>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId={`${index}`}>
              <UncontrolledAccordion flush className="banjos-accordian">
                {column.itemIds.map((x, i) => {
                  const item = state.items.find((y) => y.id === x);
                  return (
                    <AccordionItem>
                      <AccordionHeader
                        targetId={`sub-${i}`}
                        className="sub-head sub-item"
                      >
                        <div className="header">
                          <div className="text">{parse(item.content)}</div>
                          <div>
                            {item.show ? (
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onHide("item", item.id, null);
                                }}
                                src="/icons/eye_open.svg"
                              />
                            ) : (
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onHide("item", item.id, null);
                                }}
                                src="/icons/eye_close.svg"
                              />
                            )}
                            <img
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeleteItem(index, x);
                              }}
                              width={17}
                              height={20}
                              src="/icons/delete_icon.svg"
                            />
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={`sub-${i}`} className="pt-3">
                        <BanjosInput
                          type="text"
                          name="link"
                          value={item.link}
                          placeholder="link"
                          onChange={(e) => onChangeItem(x, e)}
                          externalLink={true}
                          onExternalChange={onExternalChange}
                          externalLinkValue={item.isExternal}
                          id={item.id}
                        />
                      </AccordionBody>
                    </AccordionItem>
                  );
                })}
                <div className="add-category">
                  <div>Add Item</div>
                  <img
                    onClick={() => onAddItem(index)}
                    className="cursor-pointer"
                    src="/icons/add_icon_black.svg"
                  />
                </div>
              </UncontrolledAccordion>
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
      <div className="add-category">
        <div>Add Category</div>
        <img
          onClick={() => onAddColumn()}
          className="cursor-pointer"
          src="/icons/add_icon_black.svg"
        />
      </div>
    </div>
  );
};
