import styled from "styled-components";
import { FRONT_END_BASE_URL } from "../../../shared";
import parse from "html-react-parser";

export function CategoryBlock(data) {
  const { categoryTitle, categories } = data.data;
  const RoundWrapper = styled.div`
    background: ${(props) => props.theme};
  `;
  const Title = styled.span`
    color: ${categoryTitle.color};
  `;

  return (
    <div className="category-block-wrapper">
      <div className="category-block-container container container-max">
        <div className="category-block-title">
          <Title className="title">{parse(categoryTitle.text ?? "")}</Title>
        </div>
        <div className="category-block-items">
          {categories.map((data, index) => {
            let link = `${FRONT_END_BASE_URL}/menu/${data.slug}`;
            if (data.link) {
              link += `?${data.link}`;
            }
            return (
              <div className="category-items" key={index}>
                <a href={link}>
                  <RoundWrapper
                    className="category-cover-box"
                    theme={data.color}
                  >
                    <img className="" src={data.cover} />
                  </RoundWrapper>
                </a>
                <span className="category-name">{data.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
