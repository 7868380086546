import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor as BalloonEditor } from "ckeditor5-custom-build/build/ckeditor";
import { useContext, useEffect, useState } from "react";
import { AppStateContext, onElementClick } from "../../../shared";

export function TextBlock() {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { descriptionBox1, descriptionBox2, doodleImage } =
    appState.storeTemplate;
  const { selectedElement } = appState;
  const [isReady, setIsReady] = useState(false);
  const onTextChange = (data, name) => {
    appScopeDispatch({
      type: "ON_TEXT_BOX_CHANGE",
      payload: {
        name: name,
        value: data,
      },
    });
  };
  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <div className="text-block-wrapper">
      {isReady && (
        <div
          className={`text-block-container ${
            selectedElement === "doodleImage" ? "element-selected" : ""
          }`}
          style={{
            backgroundImage: `linear-gradient(rgba(255,255,255,.39), rgba(255,255,255,.39)),url(${doodleImage.url})`,
          }}
          onClick={(e) => {
            onElementClick(appScopeDispatch, "doodleImage", "image");
          }}
        >
          <div className="text-items-container container container-max">
            <div
              className="text-box-item"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CKEditor
                config={{
                  toolbar: ["heading", "bulletedList"],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                    ],
                  },
                  removePlugins: ["Title"],
                }}
                editor={BalloonEditor}
                data={descriptionBox1}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onTextChange(data, "descriptionBox1");
                }}
              />
            </div>
            <div
              className="text-box-item"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <CKEditor
                config={{
                  toolbar: ["heading", "bulletedList"],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                    ],
                    removePlugins: ["Title"],
                  },
                }}
                editor={BalloonEditor}
                data={descriptionBox2}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  onTextChange(data, "descriptionBox2");
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
