import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CustomTable } from "../../shared/components/CustomTable";
import { useHomePage } from "./hooks";
import {
  BanjosInput,
  BanjosLoader,
  FRONT_END_BASE_URL,
  ModalContainer,
} from "../../shared";
import { Button } from "reactstrap";
import { FooterSettings } from "./components/FooterSettings";

export const HomePage = () => {
  const {
    state,
    deleteTemplate,
    onSearch,
    resetGlobalState,
    toggleModal,
    changeTab,
    currentTab,
  } = useHomePage();
  const { filteredData, isBusy, searchText, isOpen, id } = state;
  const navigate = useNavigate();

  const ActionButtons = ({ editAction, deleteAction, showDelete = false }) => {
    return (
      <div className="action-buttons">
        <img onClick={editAction} src="/icons/edit_icon.svg" />
        {showDelete && (
          <img onClick={deleteAction} src="/icons/delete_icon.svg" />
        )}
      </div>
    );
  };

  const EmptyPages = () => {
    return (
      <div className="empty-pages">
        <img alt="empty-pages" src="/icons/ic_empty_pages.svg" />
        <span>No pages Available</span>
      </div>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Store Name",
        accessor: "title",
      },
      {
        Header: "URL",
        accessor: "slug",
        accessor: ({ slug }) => {
          return (
            <a href={`${FRONT_END_BASE_URL}/${slug}`} target="_blank">
              {`${FRONT_END_BASE_URL}/${slug}`}{" "}
            </a>
          );
        },
      },
      {
        Header: "Last Saved",
        accessor: "last_drafted",
        accessor: ({ last_drafted }) => {
          return (
            <div className="save-badge">
              <div>{last_drafted}</div>
            </div>
          );
        },
      },
      {
        Header: "Last Published",
        accessor: "last_published",
        accessor: ({ last_published }) => {
          return (
            <div className="publish-badge">
              <div>{last_published}</div>
            </div>
          );
        },
      },
      {
        id: "action",
        accessor: "id",
        accessor: (row, index) => {
          return (
            <ActionButtons
              editAction={() => navigate(`/edit/storeTemplate/${row.slug}`)}
              deleteAction={() => toggleModal(row.id)}
            />
          );
        },
      },
    ],
    []
  );

  const columns2 = React.useMemo(
    () => [
      {
        Header: "Page Name",
        accessor: "title",
      },
      {
        Header: "URL",
        accessor: "slug",
        accessor: ({ slug }) => {
          return (
            <a href={`${FRONT_END_BASE_URL}/${slug}`} target="_blank">
              {`${FRONT_END_BASE_URL}/${slug}`}{" "}
            </a>
          );
        },
      },
      {
        Header: "Last Saved",
        accessor: "last_drafted",
        accessor: ({ last_drafted }) => {
          return (
            <div className="save-badge">
              <div>{last_drafted}</div>
            </div>
          );
        },
      },
      {
        Header: "Last Published",
        accessor: "last_published",
        accessor: ({ last_published }) => {
          return (
            <div className="publish-badge">
              <div>{last_published}</div>
            </div>
          );
        },
      },
      {
        id: "action",
        accessor: "id",
        accessor: (row, index) => {
          return (
            <ActionButtons
              editAction={() =>
                navigate(`/edit/custom/${row.slug}?mode=desktop`)
              }
              deleteAction={() => toggleModal(row.id)}
              showDelete
            />
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="homepage-wrapper">
        <>
          <header className="homepage-header-wrapper">
            <div
              className={`tab cursor-pointer ${currentTab === 1 && "active"}`}
              onClick={() => currentTab !== 1 && changeTab(1)}
            >
              <span>Store pages</span>
            </div>
            <div
              className={`tab cursor-pointer ${currentTab === 2 && "active"}`}
              onClick={() => currentTab !== 2 && changeTab(2)}
            >
              <span>Custom pages</span>
            </div>
            <div
              className={`tab cursor-pointer ${currentTab === 3 && "active"}`}
              onClick={() => currentTab !== 3 && changeTab(3)}
            >
              <span>Footer Settings</span>
            </div>
          </header>
          {currentTab === 1 ? (
            <div className="table-pagelist">
              <div className="search-and-filter">
                <div className="search-list">
                  <img src="/icons/ic_search.svg" />
                  <input
                    placeholder="Search here"
                    value={searchText}
                    onChange={(e) => onSearch(e)}
                  />
                </div>
              </div>
              {isBusy ? (
                <BanjosLoader />
              ) : filteredData.length === 0 ? (
                <EmptyPages />
              ) : (
                <CustomTable columns={columns} data={filteredData} />
              )}
            </div>
          ) : currentTab === 2 ? (
            <>
              <div className="table-pagelist">
                <div className="search-and-filter">
                  <div className="search-list">
                    <img src="/icons/ic_search.svg" />
                    <input
                      placeholder="Search here"
                      value={searchText}
                      onChange={(e) => onSearch(e)}
                    />
                  </div>

                  <button
                    onClick={() => {
                      resetGlobalState();
                      navigate("/create/custom?mode=desktop");
                    }}
                    className="primary-btn"
                  >
                    <img src="/icons/new_page.svg" /> New Page
                  </button>
                </div>
                {isBusy ? (
                  <BanjosLoader />
                ) : filteredData.length === 0 ? (
                  <EmptyPages />
                ) : (
                  <CustomTable columns={columns2} data={filteredData} />
                )}
              </div>
            </>
          ) : currentTab === 3 ? (
            <FooterSettings />
          ) : null}
        </>
      </div>
      <ModalContainer
        isOpen={isOpen}
        centered
        toggleModal={toggleModal}
        header="Are you sure?"
        body={"Are you sure want to delete page?"}
        footer={
          <>
            <Button color="danger" onClick={() => deleteTemplate(id)}>
              Delete
            </Button>
            <Button color="secondary" onClick={() => toggleModal()}>
              Cancel
            </Button>
          </>
        }
      />
    </>
  );
};
