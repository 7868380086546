import { Routes, Route, useLocation } from "react-router-dom";
import { UiEditor, HomePage, Preview } from "./modules";
import { BanjosNavbar, Unauthorized } from "./shared";
import { AppScopeProvider } from "./shared";

const App = () => {
  const { pathname } = useLocation();
  const isPreview =
    pathname.includes("/preview") || pathname.includes("/unauthorized");
  return (
    <AppScopeProvider>
      {!isPreview ? <BanjosNavbar /> : ""}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/create/:type" element={<UiEditor />} />
        <Route path="/edit/:type/:slug" element={<UiEditor />} />
        <Route path="/preview/:type" element={<Preview />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </AppScopeProvider>
  );
};

export default App;
