import React, { useCallback } from "react";
import { useImmer } from "use-immer";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API } from "../api";

export const Dropzone = ({ label, onUpload }) => {
  const [state, setState] = useImmer({
    loading: false,
  });

  const onDrop = useCallback((acceptedFiles) => {
    setState((draft) => {
      draft.loading = true;
    });
    const formData = new FormData();
    formData.append("image", acceptedFiles[0]);
    API.post("page-template/image-upload", formData)
      .then((res) => {
        if (res.data.success) {
          onUpload(res.data.data.image_url);
          setState((draft) => {
            draft.loading = false;
          });
        }
      })
      .catch((err) => {
        toast.error("An error has occured while uploading image");
        setState((draft) => {
          draft.loading = false;
        });
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: state.loading,
  });
  return (
    <div className="banjos-input-wrapper">
      {label && <div className="banjos-input-label">{label}</div>}
      <div className="banjos-input-image" {...getRootProps()}>
        <input {...getInputProps()} disabled={true} />
        {state.loading ? (
          <div className="image-loading" />
        ) : (
          <>
            <img src="/icons/edit_image.svg" className="edit_image_icon" />
            {isDragActive ? (
              <span>Drop images here ...</span>
            ) : (
              <span>Drop image Here</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};
