import React, { useContext } from "react";
import { AppStateContext, BanjosInput, Dropzone } from "../../../shared";

export const EditImage = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { selectedElement, selectedTemplate } = appState;
  const onTextChange = (e) => {
    const { value, name } = e.target;
    appScopeDispatch({
      type: "ONCHANGE_IMAGE_TEXT",
      payload: {
        selectedElement,
        name,
        value,
      },
    });
  };
  const onUpload = (url) => {
    appScopeDispatch({
      type: "ONCHANGE_BANNER_IMAGE",
      payload: {
        url,
      },
    });
  };
  return (
    <>
      <Dropzone
        label={appState[selectedTemplate][selectedElement].componentName}
        onUpload={onUpload}
      />
      <BanjosInput
        type="text"
        label="Image Alternative Text"
        name="alt"
        value={appState[selectedTemplate][selectedElement].alt}
        onChange={(e) => onTextChange(e)}
      />
      <BanjosInput
        type="textarea"
        label="Image Schema"
        name="imageSchema"
        value={appState[selectedTemplate][selectedElement].imageSchema}
        onChange={(e) => onTextChange(e)}
      />
      <BanjosInput
        type="textarea"
        label="Image Description"
        name="imageDescription"
        value={appState[selectedTemplate][selectedElement].imageDescription}
        onChange={(e) => onTextChange(e)}
      />
    </>
  );
};
