import React, { useContext } from "react";
import {
  EditImage,
  EditText,
  EditFavouritesSection,
  EditCategoryLink,
  EditWeAreAvailable,
  EditSupporters,
  EditOrderOnlineSection,
} from "..";
import { AppStateContext } from "../../../shared";

export const BasicEditor = () => {
  const { appState } = useContext(AppStateContext);
  const { selectedElementType } = appState;
  switch (selectedElementType) {
    case "image":
      return <EditImage />;
    case "text":
      return <EditText />;
    case "category-link":
      return <EditCategoryLink />;
    case "order-online-section":
      return <EditOrderOnlineSection />;
    case "favourite-section":
      return <EditFavouritesSection />;
    case "weAreAvailableEdior":
      return <EditWeAreAvailable />;
    case "proudlySuppEdior":
      return <EditSupporters />;
  }
};
