import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export const CkEditorMobile = ({
  custom_config,
  BalloonEditor,
  mobile_body,
  onChange,
}) => {
  return (
    <CKEditor
      config={custom_config}
      editor={BalloonEditor}
      data={mobile_body}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data, "mobile");
      }}
    />
  );
};
