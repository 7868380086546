import React, { useContext } from "react";
import {
  AppStateContext,
  BanjosInput,
  FRONT_END_BASE_URL,
} from "../../../shared";

export const EditOrderOnlineSection = () => {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { orderOnlineSection } = appState.storeTemplate;
  const { backgroundColor, headerColor, descriptionColor, btnBgColor, link } =
    orderOnlineSection;
  const fontDetails = {
    fontName: "Grenette Pro",
    fontWeight: "Regular",
    fontSize: "16",
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    appScopeDispatch({
      type: "ON_ORDER_ONLINE_SECTION_CHANGE",
      payload: {
        name,
        value,
      },
    });
  };
  return (
    <>
      <BanjosInput
        type="color"
        label="Background color"
        name="backgroundColor"
        value={backgroundColor}
        onChange={onChange}
      />
      <BanjosInput
        type="color"
        label="Header colour"
        fontDetails={fontDetails}
        name="headerColor"
        value={headerColor}
        onChange={onChange}
      />
      <BanjosInput
        type="color"
        label="Description colour"
        fontDetails={fontDetails}
        name="descriptionColor"
        value={descriptionColor}
        onChange={onChange}
      />
      <BanjosInput
        type="color"
        label="Button colour"
        name="btnBgColor"
        value={btnBgColor}
        onChange={onChange}
      />
      <BanjosInput
        type="text"
        name="link"
        description={`${FRONT_END_BASE_URL}?`}
        value={link}
        onChange={onChange}
        placeholder="UTM code"
      />
    </>
  );
};
