import styled from "styled-components";
import moment from "moment";
import { useState } from "react";
import { ReactstrapModal } from "../../../shared";

export function MainBannerBlock(props) {
  const { selectedStore, data } = props;
  const { bannerImage1, bannerTitle1 } = data.storeTemplate;
  const { opening_hours, openday_text } = data;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const Title = styled.h1`
    color: ${bannerTitle1.color};
  `;

  return (
    <>
      <div className="main-banner-block-wrapper">
        <img
          src={bannerImage1.url}
          alt={bannerImage1.alt}
          title={bannerImage1.imageDescription}
          layout="fill"
          loading="lazy"
          className="image-wrapper"
        />
        <div className="content-container">
          <Title className="store-name">{bannerTitle1.text}</Title>
          <div className="open-time-container">
            <div className="open-time-title">{openday_text}</div>
            <div className="open-times-wrapper">
              {opening_hours?.map((data, i) => {
                return (
                  <div key={i} className="open-time ">
                    {data}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="buttons-wrapper">
            <div className="call-btn" onClick={handleShow}>
              <img src="/icons/ic_phone.svg" />
              <span>CALL US</span>
            </div>
            <a
              target="_blank"
              href={encodeURI(
                `https://www.google.com/maps?daddr=${selectedStore?.name}`
              )}
            >
              <div className="direction-btn">
                <img src="/icons/ic_direction.svg" />
                <span>DIRECTION</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <ReactstrapModal
        isOpen={show}
        toggle={() => handleClose()}
        bodyClassName={`py-5`}
        dialogClassName="call-modal"
        size="sm"
        centered={true}
        content={
          <>
            <div className="text-center call-middle">
              <a href={"tel:" + selectedStore?.phone}>
                <img src="/images/phone-icon.svg"></img>
              </a>
              <h3 className="head-16 my-2">Call Now</h3>
              <h4
                className="subtitle-13 text-uppercase"
                style={{ color: "#3B513D" }}
              >
                {selectedStore?.name}
              </h4>
              <a href={"tel:" + selectedStore?.phone}>
                <p className="text-muted subtitle-13">
                  {selectedStore.phone?.replace(
                    /(\d{4})(\d{3})(\d{3})/,
                    "$1 $2 $3"
                  )}
                </p>
              </a>
            </div>
          </>
        }
      />
    </>
  );
}
