import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export const CkEditorDesktop = ({
  custom_config,
  BalloonEditor,
  custom,
  onChange,
}) => {
  return (
    <CKEditor
      config={custom_config}
      editor={BalloonEditor}
      data={custom}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data, "desktop");
      }}
    />
  );
};
