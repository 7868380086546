import {
  CategoryBlock,
  FavouriteCategoryBlock,
  ImageBannerBlock,
  MainBannerBlock,
  OrderOnlineBlock,
  ProudlySupportBlock,
  TextBlock,
  WeAreAvailableBlock,
} from "../components";
import {
  TemplateNavbar,
  TemplateFooter,
  AppStateContext,
} from "../../../shared";
import { useContext } from "react";

export function StoreTemplate(props) {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const {
    bannerTitle2,
    bannerTitle3,
    isSectionVisible,
    bannerImage2,
    bannerImage3,
  } = appState.storeTemplate;
  const {
    mainBanner,
    orderSection,
    banner2,
    textSection,
    banner3,
    orderOnlineSection,
    favouriteSection,
    deliveryPartners,
    supporterSection,
  } = isSectionVisible;
  return (
    <>
      <TemplateNavbar />
      <div className="store-landing-wrapper editor-store-landing-wrapper">
        {mainBanner && <MainBannerBlock />}
        {orderSection && <CategoryBlock />}
        {banner2 && (
          <ImageBannerBlock
            data={bannerTitle2}
            textElement="bannerTitle2"
            imageElement="bannerImage2"
            appScopeDispatch={appScopeDispatch}
            image={bannerImage2}
          />
        )}
        {textSection && <TextBlock />}
        {banner3 && (
          <ImageBannerBlock
            data={bannerTitle3}
            textElement="bannerTitle3"
            imageElement="bannerImage3"
            appScopeDispatch={appScopeDispatch}
            image={bannerImage3}
          />
        )}
        {orderOnlineSection && <OrderOnlineBlock />}
        {favouriteSection && <FavouriteCategoryBlock />}
        {deliveryPartners && <WeAreAvailableBlock />}
        {supporterSection && <ProudlySupportBlock />}
      </div>
      <TemplateFooter />
    </>
  );
}
