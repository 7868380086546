import {
  CategoryBlock,
  FavouriteCategoryBlock,
  ImageBannerBlock,
  MainBannerBlock,
  OrderOnlineBlock,
  ProudlySupportBlock,
  TextBlock,
  WeAreAvailableBlock,
} from "../components";
import {
  TemplateNavbar,
  TemplateFooter,
  AppStateContext,
} from "../../../shared";
import { useContext } from "react";

export const StorePagePreview = () => {
  const appState = JSON.parse(localStorage.getItem("admin-data"));

  const { isSectionVisible } = appState.storeTemplate;
  const {
    mainBanner,
    orderSection,
    banner2,
    textSection,
    banner3,
    orderOnlineSection,
    favouriteSection,
    deliveryPartners,
    supporterSection,
  } = isSectionVisible;
  return (
    <>
      <TemplateNavbar />
      <div className="store-landing-wrapper">
        {mainBanner && (
          <MainBannerBlock
            data={appState}
            selectedStore={appState.selectedStore}
          />
        )}
        {orderSection && <CategoryBlock data={appState?.storeTemplate} />}
        {banner2 && (
          <ImageBannerBlock
            textElement={appState?.storeTemplate.bannerTitle2}
            imageElement={appState?.storeTemplate.bannerImage2}
          />
        )}
        {textSection && <TextBlock data={appState?.storeTemplate} />}
        {banner3 && (
          <ImageBannerBlock
            textElement={appState?.storeTemplate.bannerTitle3}
            imageElement={appState?.storeTemplate.bannerImage3}
          />
        )}
        {orderOnlineSection && (
          <OrderOnlineBlock data={appState?.storeTemplate} />
        )}
        {favouriteSection && (
          <FavouriteCategoryBlock data={appState?.storeTemplate} />
        )}
        {deliveryPartners && (
          <WeAreAvailableBlock data={appState?.storeTemplate} />
        )}
        {supporterSection && (
          <ProudlySupportBlock data={appState?.storeTemplate} />
        )}
      </div>
      <TemplateFooter />
    </>
  );
};
