import { useRef, useContext } from "react";
import ContentEditable from "react-contenteditable";
import { onElementClick, onTextChange, AppStateContext } from "../../../shared";

export function ImageBannerBlock(props) {
  const { appState } = useContext(AppStateContext);
  const { selectedElement } = appState;
  const { data, textElement, imageElement, appScopeDispatch, image } = props;
  const ref = useRef(null);
  return (
    <div className="image-banner-block-wrapper">
      <div
        className={`image-banner-block-container ${
          selectedElement === imageElement ? "element-selected" : ""
        }`}
      >
        <img
          src={image.url}
          alt="banjos-banner"
          className="banner-image"
          layout="fill"
          loading="lazy"
          onClick={(e) => {
            onElementClick(appScopeDispatch, imageElement, "image");
          }}
        />
        <ContentEditable
          className="banner-fancy-text"
          onClick={(e) => {
            e.stopPropagation();
            onElementClick(appScopeDispatch, textElement, "text");
          }}
          style={{
            color: data.color,
          }}
          innerRef={ref}
          html={data.text} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={(e) =>
            onTextChange(appScopeDispatch, textElement, e.target.value)
          } // handle innerHTML change
          tagName="span"
        />
      </div>
    </div>
  );
}
