import { useContext, useRef } from "react";
import {
  AppStateContext,
  onAddLogo,
  onDeleteLogo,
  onSelectLogoElement,
} from "../../../shared";

export function WeAreAvailableBlock() {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { weAreAvailable } = appState.storeTemplate;
  const { selectedElement, index } = appState;
  const LogoRef = useRef(null);
  const onLogoClick = () => {
    LogoRef.current.click();
  };
  return (
    <div className="we-are-available-block-wrapper">
      <div className="we-are-available-block-container container container-max">
        <div
          className="we-are-available-box"
          style={{
            backgroundColor: `#F7F7F7`,
          }}
        >
          <div
            className="we-are-available-title"
            style={{
              color: `#000000`,
            }}
          >
            We are available on
          </div>
          <div className="we-are-available-items">
            {weAreAvailable.map((item, i) => {
              if (item.show) {
                return (
                  <div
                    onClick={() => {
                      onSelectLogoElement(
                        appScopeDispatch,
                        "weAreAvailable",
                        "weAreAvailableEdior",
                        i
                      );
                    }}
                    key={i}
                    className={`we-are-available-item ${
                      selectedElement === "weAreAvailable" && index === i
                        ? "element-selected"
                        : ""
                    }`}
                    style={{
                      backgroundColor: item.btnColor,
                    }}
                  >
                    <img src={item.logo} />
                    <img
                      onClick={() =>
                        onDeleteLogo(appScopeDispatch, "weAreAvailable", i)
                      }
                      className="delete-btn"
                      src="/icons/delete_icon.svg"
                    />
                  </div>
                );
              }
            })}
            <button
              onClick={() => onLogoClick()}
              className="secondary-btn we-are-available-add-item"
            >
              <input
                type="file"
                onChange={(e) =>
                  onAddLogo(appScopeDispatch, "weAreAvailable", e)
                }
                style={{ display: "none" }}
                ref={LogoRef}
              />
              <img src="/icons/add_icon.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
