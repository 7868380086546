import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const ReactstrapModal = ({
  isOpen,
  toggle,
  contentClassName,
  size,
  centered,
  content,
  showBackButton,
  onShowBackButtonClick,
  disableHeader,
  backdropClassName,
  heading = "",
  bodyClassName,
  dialogClassName,
  onTop = false,
  fullscreen = false,
  showCloseButton = true,
}) => {
  let someProps = {};
  if (showCloseButton) {
    someProps = {
      toggle: toggle,
      close: <button className="close" onClick={toggle}></button>,
    };
  }
  return (
    <Modal
      zIndex={onTop ? 2000 : 1050}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={contentClassName}
      size={size}
      backdrop="static"
      showBackButton={false}
      centered={centered}
      backdropClassName={backdropClassName}
      className={dialogClassName}
      fullscreen={fullscreen}
    >
      {disableHeader ? null : (
        <ModalHeader {...someProps}>
          {showBackButton ? (
            <div className="backArrow" onClick={onShowBackButtonClick}></div>
          ) : null}
          {heading}
        </ModalHeader>
      )}
      <ModalBody className={bodyClassName}>{content}</ModalBody>
    </Modal>
  );
};
