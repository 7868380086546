export function TemplateNavbar(props) {
  return (
    <div className="Navbar-wrapper">
      <div className="Navbar-content">
        <div className="Navbar-container container-max container">
          <div className="logo-section">
            <img
              className="text-logo"
              alt="logo"
              src="/images/BanjosTextLogo.svg"
            />
          </div>
          <div className="user-section">
            <div className="user-sign-in">
              <img className="user-logo" alt="logo" src="/icons/ic_user.svg" />
              <span className="signin-text">Sign In</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
