import { useContext } from "react";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, AppStateContext } from "../../../shared";
import { EditorTemplates } from "../../EditorTemplates/EditorTemplates";

export const EditorCanvas = () => {
  const [queryParameters] = useSearchParams();
  const isMobileView = queryParameters.get("mode") === "mobile";
  const params = useParams();
  const { type } = params;

  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const getTemplateBySlug = (slug) => {
    API.get(`page-template/detail/${slug}`)
      .then((res) => {
        if (res.data.success) {
          const {
            store_id,
            gtm,
            meta_description,
            meta_keyword,
            meta_title,
            selectedTemplate,
            slug,
            title,
            body,
            id,
            status,
            opening_hours,
            openday_text,
            category,
          } = res.data.data;
          let modifiedCategories = [];
          if (selectedTemplate === "storeTemplate") {
            for (let i = 0; i < category.length; i++) {
              const isExist = body.categories.find(
                (item) => item.id === category[i].id
              );
              if (isExist) {
                modifiedCategories.push({
                  ...category[i],
                  link: isExist.link,
                });
              } else {
                modifiedCategories.push({
                  ...category[i],
                  link: "",
                });
              }
            }
          }
          let data = {
            initialSlug: slug,
            id,
            status,
            gtm,
            meta_description,
            meta_keyword,
            meta_title,
            selectedTemplate,
            slug,
            title,
            opening_hours,
            openday_text,
            storeTemplate: type === "storeTemplate" && {
              ...body,
              categories: modifiedCategories,
            },
            custom: type === "custom" && body ? body : "",
            selectedStore: appState.stores.find(
              (store) => store.id === store_id
            ),
          };
          if (type === "custom" && res.data.data?.mobile_body_enabled) {
            data = {
              ...data,
              mobile_body: res.data.data?.mobile_body,
              mobile_body_enabled: res.data.data?.mobile_body_enabled,
            };
          }
          appScopeDispatch({
            type: "UPDATE_STATE",
            data,
          });
        } else {
          toast.error(`${res.data.message}`);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An Error has Occured");
      });
  };
  useEffect(() => {
    if (type) {
      appScopeDispatch({
        type: "SET_TEMPLATE_TYPE",
        payload: {
          data: type,
        },
      });
    }
  }, [type]);
  useEffect(() => {
    if (params.slug && appState?.stores?.length > 0) {
      getTemplateBySlug(params.slug);
    }
  }, [params.slug, appState.stores]);
  return (
    <div className={`editor-canvas-wrapper ${isMobileView && "mobile"}`}>
      <EditorTemplates type={params.type} />
    </div>
  );
};
