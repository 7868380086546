import React, { useContext } from "react";
import { TemplateFooter, TemplateNavbar } from "../../../shared";
import parse from "html-react-parser";
import { useNavigate, useSearchParams } from "react-router-dom";
import webIcon from "../../../assets/images/web-icon.svg";
import mobileIcon from "../../../assets/images/mobile-icon.svg";

export const CkEditorPreview = () => {
  const appState = JSON.parse(localStorage.getItem("admin-data"));
  const { mobile_body, custom } = appState;
  const [queryParameters] = useSearchParams();
  const isMobileView = queryParameters.get("mode") === "mobile";
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  return (
    <>
      <div className={`template-preview-canvas ${isMobileView && "mobile"}`}>
        <div className="template-preview-canvas-wrap">
          <div>
            <TemplateNavbar />
            <div className="ck-content">
              {isMobileView && mobile_body ? parse(mobile_body) : parse(custom)}
            </div>
            <TemplateFooter />
          </div>
        </div>
      </div>
      <div className="deviceToggle">
        {isOpen && (
          <div
            className="dropdownDevice"
            onClick={() => {
              navigate(
                {
                  search: `mode=${isMobileView ? "desktop" : "mobile"}`,
                },
                { replace: true }
              );
              setIsOpen(!isOpen);
            }}
          >
            <img src={isMobileView ? webIcon : mobileIcon} />
          </div>
        )}
        <div className="selectedDevice" onClick={() => setIsOpen(!isOpen)}>
          <img src={isMobileView ? mobileIcon : webIcon} />
        </div>
      </div>
    </>
  );
};
