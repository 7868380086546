import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { AppStateContext } from "../../../shared";
import { BasicEditor, SeoEditor, SectionsEditor } from "../../EditorTools";

export const EditorToolbar = () => {
  const { appState } = useContext(AppStateContext);
  const { selectedTemplate } = appState;
  const [state, setState] = useState("seo");
  const isCustom = selectedTemplate === "custom";
  useEffect(() => {
    if (selectedTemplate === "custom") {
      setState("seo");
    }
  }, [selectedTemplate]);
  const changeTab = (e) => {
    setState(e);
  };
  return (
    <div className="editor-toolbar-wrapper">
      <div className="editor-toolbar-header-wrapper">
        {!isCustom && (
          <div
            className={`tools-tab ${state === "edit" ? "active" : ""}`}
            onClick={() => !isCustom && changeTab("edit")}
          >
            Edit
          </div>
        )}

        <div
          className={`tools-tab ${state === "seo" ? "active" : ""}`}
          onClick={() => changeTab("seo")}
        >
          SEO
        </div>
        {!isCustom && (
          <div
            className={`tools-tab ${state === "sections" ? "active" : ""}`}
            onClick={() => !isCustom && changeTab("sections")}
          >
            Sections
          </div>
        )}
      </div>
      <div className="editor-toolbar-tools-wrapper">
        <div>
          {state === "edit" ? (
            <BasicEditor />
          ) : state === "seo" ? (
            <SeoEditor />
          ) : (
            <SectionsEditor />
          )}
        </div>
      </div>
    </div>
  );
};
