import { useContext, useRef } from "react";
import ContentEditable from "react-contenteditable";
import { AppStateContext } from "../../../shared/contexts";
import { onTextChange, onElementClick } from "../../../shared/actions";
import moment from "moment";

export function MainBannerBlock() {
  const { appState, appScopeDispatch } = useContext(AppStateContext);
  const { bannerTitle1, bannerImage1 } = appState.storeTemplate;
  const { selectedElement, opening_hours, openday_text } = appState;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const contentEditableHeader = useRef(null);

  return (
    <div
      className={`main-banner-block-wrapper ${
        selectedElement === "bannerImage1" ? "element-selected" : ""
      }`}
    >
      <img
        src={bannerImage1.url}
        onClick={(e) => {
          onElementClick(appScopeDispatch, "bannerImage1", "image");
        }}
        className="image-wrapper"
      />
      <div className="content-container">
        <h2
          className={`store-name ${
            selectedElement === "bannerTitle1" && "text-selected"
          }`}
          style={{
            color: bannerTitle1.color,
          }}
          onClick={(e) => {
            e.stopPropagation();
            onElementClick(appScopeDispatch, "bannerTitle1", "text");
          }}
        >
          {bannerTitle1.text}
        </h2>

        <div className="open-time-container">
          <div
            className="open-time-title"
            style={{
              color: `#ffffff`,
            }}
          >
            {openday_text}
          </div>
          <div className="open-times-wrapper">
            {opening_hours?.map((data, i) => {
              return (
                <div
                  key={i}
                  className="open-time "
                  style={{
                    color: `#ffffff`,
                  }}
                >
                  {data}
                </div>
              );
            })}
          </div>
        </div>
        <div className="buttons-wrapper">
          <div
            className="call-btn"
            style={{
              backgroundColor: `#fffceb`,
            }}
          >
            <img src="/icons/ic_phone.svg" />
            <span
              style={{
                color: `#5E7360`,
              }}
            >
              CALL US
            </span>
          </div>
          <div
            className="direction-btn"
            style={{
              backgroundColor: `#fffceb`,
            }}
          >
            <img src="/icons/ic_direction.svg" />
            <span
              style={{
                color: `#5E7360`,
              }}
            >
              DIRECTION
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
